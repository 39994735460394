import OrderState, { IOrderState } from '@/store/modules/common/OrderState';
import { getErrorMessage, getOrdersApi, getProductsApi, getStripeApi } from '@/castapi/helpers';
import { OrderSearchResponseDto } from '@/castapi';
import { AppLogger } from '@/logger';

const order = new OrderState();

const logger = new AppLogger('adminOrders state');

interface IAdminOrderState extends IOrderState {
  orders: OrderSearchResponseDto[];
  ordersLoading: boolean;
  ordersLoadError: null | Error;
}

const initialState = (): IAdminOrderState => ({
  ...order.state(),
  orders: [],
  ordersLoading: false,
  ordersLoadError: null,
  productsMap: {},
  productLoadError: null,
  productsLoading: false,
  shopProducts: [],
});

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    ...order.mutations,

    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
    ORDERS_LOADING(state) {
      state.ordersLoading = true;
      state.ordersLoadError = null;
    },
    ORDERS_LOADED(state, orders) {
      state.orders = orders;
      state.ordersLoading = false;
    },
    ORDERS_LOAD_ERROR(state, payload) {
      state.ordersLoading = false;
      state.ordersLoadError = getErrorMessage(payload);
    },
    SET_RENEW_UPGRADE_PRODUCTS_FOR_FULFILLED_ORDER(state, payload) {
      state.productsLoading = false;
      state.renewUpgrade = {
        ...initialState().renewUpgrade,
        renewProducts: payload,
      };
    },
    INVOICE_SENT(state) {
      state.isLoading = false;
    },
    INVOICE_VOIDED(state) {
      state.isLoading = false;
    },
  },
  actions: {
    ...order.actions,

    resetState({ commit }) {
      commit('RESET_STATE');
    },
    async loadOrders({ commit, rootGetters }, payload) {
      const { limit, offset, searchText, sortBy, sortDesc, fulfilled, refunded, notPaid, invoiceSent } = payload || {};
      commit('ORDERS_LOADING');
      try {
        const response = await getOrdersApi(rootGetters['login/accessToken']).ordersControllerGetAllOrders(
          limit,
          offset,
          searchText,
          sortBy,
          sortDesc,
          fulfilled,
          refunded,
          notPaid,
          invoiceSent,
        );
        commit('ORDERS_LOADED', response.data);
      } catch (error) {
        commit('ORDERS_LOAD_ERROR', error);
        logger.captureStoreError('loadOrders', error, { limit, offset, searchText, sortBy, sortDesc });
      }
    },

    async getUserUnfinishedOrder({ commit, dispatch, getters, rootGetters }, userId) {
      try {
        if (!userId) {
          return;
        }
        const token = rootGetters['login/accessToken'];
        if (!token || getters.cartProducts?.length) {
          return;
        }
        if (!getters.allProducts?.length) {
          await dispatch('getProductsFromApi', true);
          return;
        }
        commit('LOADING');
        const response = await getOrdersApi(token).ordersControllerGetUserUnfinishedOrder(userId);
        await dispatch('orderLoaded', response.data);
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('getUserUnfinishedOrder', error, { userId });
      }
    },

    async getRenewUpgradeProductForFulfilledOrder({ commit, rootGetters }, productId) {
      commit('PRODUCTS_LOADING');
      try {
        const token = rootGetters['login/accessToken'];
        const response = await getProductsApi(token).productsControllerFindOne(productId);
        commit('SET_RENEW_UPGRADE_PRODUCTS_FOR_FULFILLED_ORDER', [response.data]);
      } catch (error) {
        commit('PRODUCTS_LOAD_ERROR', error);
        logger.captureStoreError('getRenewUpgradeProductForFulfilledOrder', error, { productId });
      }
    },

    async sendInvoice({ commit, getters, rootGetters }) {
      const orderId = getters.orderId;
      if (!orderId) {
        return;
      }
      commit('LOADING');
      try {
        const token = rootGetters['login/accessToken'];
        await getStripeApi(token).stripeControllerSendStripeInvoiceForOrder({ orderId });
        commit('INVOICE_SENT');
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('sendInvoice', error);
      }
    },

    async voidInvoice({ commit, getters, rootGetters }) {
      const orderId = getters.orderId;
      if (!orderId) {
        return;
      }
      commit('LOADING');
      try {
        const token = rootGetters['login/accessToken'];
        await getStripeApi(token).stripeControllerVoidStripeInvoiceForOrder({ orderId });
        commit('INVOICE_VOIDED');
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('voidInvoice', error);
      }
    },

    async sendCastInvoiceToPay({ commit, getters, rootGetters }) {
      const orderId = getters.orderId;
      if (!orderId) {
        return;
      }
      commit('LOADING');
      try {
        const token = rootGetters['login/accessToken'];
        await getOrdersApi(token).ordersControllerSendCastInvoiceToPay({ orderId });
        commit('INVOICE_SENT');
      } catch (error) {
        commit('ERROR', error);
        logger.captureStoreError('sendCastInvoiceToPay', error);
      }
    },
  },
  getters: {
    ...order.getters,
    orders: state => state.orders,
    ordersLoading: state => state.ordersLoading,
    ordersLoadError: state => state.ordersLoadError,
    orderCustomer: (state, getters, rootState, rootGetters) =>
      rootGetters['adminOrganizations/organizations'].find(
        organization => organization.organizationId === state.order?.organizationRef,
      ),
    isCloudOrderValidToFulfil: state => (state.order?.isCloud ? state.order?.cloudLicenseCreated : true),
  },
};
