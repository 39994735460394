import { Configuration, UsersApiFactory, UsersItemDto } from '@/castapi';
import { apiConfig } from '@/shared/constants';
import { AppLogger } from '@/logger';
import { getErrorMessage } from '@/castapi/helpers';

const logger = new AppLogger('adminPendingUsers state');

const initialState = (): {
  users: UsersItemDto[];
  usersLoading: boolean;
  usersLoadError: null | string;
  userChanging: null | number;
  deleteUserError: null | string;
  updateUserError: null | string;
  invitationSending: boolean;
  invitationError: null | string;
} => ({
  users: [],
  usersLoading: false,
  usersLoadError: null,
  userChanging: null,
  deleteUserError: null,
  updateUserError: null,
  invitationSending: false,
  invitationError: null,
});

const getUsersApi = (accessToken?) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return UsersApiFactory(config);
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    USERS_LOADING(state) {
      state.usersLoading = true;
      state.usersLoadError = null;
    },
    USERS_LOADED(state, users) {
      state.users = users;
      state.usersLoading = false;
    },
    USERS_LOAD_ERROR(state, payload) {
      state.usersLoading = false;
      state.usersLoadError = getErrorMessage(payload);
    },
    USER_DELETED(state) {
      state.userChanging = null;
    },
    DELETE_USER_ERROR(state, payload) {
      state.deleteUserError = getErrorMessage(payload);
      state.userChanging = null;
    },
    INVITATION_SENDING(state) {
      state.invitationSending = true;
      state.invitationError = null;
    },
    INVITATION_ERROR(state, error) {
      state.invitationSending = false;
      state.invitationError = getErrorMessage(error);
    },
    INVITATION_SENT(state) {
      state.invitationSending = false;
    },
    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
  },
  actions: {
    async loadUsers({ commit, rootGetters }, payload) {
      const { limit, offset, searchText, sortBy, sortDesc } = payload || {};
      commit('USERS_LOADING');
      try {
        const response = await getUsersApi(rootGetters['login/accessToken']).usersControllerGetAllPendingUsers(
          limit,
          offset,
          searchText,
          sortBy,
          sortDesc,
        );
        commit('USERS_LOADED', response.data);
      } catch (error) {
        commit('USERS_LOAD_ERROR', error);
        logger.captureStoreError('loadUsers', error, { limit, offset, searchText, sortBy, sortDesc });
      }
    },
    async sendInvitation({ commit, rootGetters }, userId) {
      commit('INVITATION_SENDING');
      try {
        await getUsersApi(rootGetters['login/accessToken']).usersControllerSendAccountActivationEmail(userId);
        commit('INVITATION_SENT');
      } catch (error) {
        commit('INVITATION_ERROR', error);
      }
    },
    async removeUser({ commit, rootGetters }, userId) {
      commit('USER_CHANGING', userId);
      try {
        await getUsersApi(rootGetters['login/accessToken']).usersControllerDeleteUser({ userId });
        commit('USER_DELETED');
      } catch (error) {
        commit('DELETE_USER_ERROR', error);
        logger.captureStoreError('removeUser', error, { userId });
      }
    },
    resetState({ commit }) {
      commit('RESET_STATE');
    },
  },
  getters: {
    users: state => state.users,
    invitationError: state => state.invitationError,
    invitationSending: state => state.invitationSending,
    usersLoading: state => state.usersLoading,
    usersLoadError: state => state.usersLoadError,
  },
};
