<template>
  <v-snackbar v-model="show" :color="color" :top="topAndCentered" :centered="topAndCentered" :timeout="timeout || 3000">
    <span v-html="message"></span>
    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Snackbar extends Vue {
  get show(): boolean {
    return this.$store.getters['snackbar/show'];
  }

  set show(value: boolean) {
    if (!value) {
      this.$store.commit('snackbar/CLOSE');
    }
  }

  get message(): string {
    return this.$store.getters['snackbar/message'];
  }

  get color(): string {
    return this.$store.getters['snackbar/color'];
  }

  get topAndCentered(): string {
    return this.$store.getters['snackbar/topAndCentered'];
  }

  get timeout(): string {
    return this.$store.getters['snackbar/timeout'];
  }
}
</script>
