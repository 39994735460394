import { getErrorMessage } from '@/castapi/helpers';
import { CloudApiFactory, CloudProductInfoDto, ComputerDto, Configuration } from '@/castapi';
import { AppLogger } from '@/logger';
import { apiConfig } from '@/shared/constants';

const logger = new AppLogger('cloudProducts state');

const getCloudApi = (accessToken?) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return CloudApiFactory(config);
};
interface CloudProductsState {
  cloudProducts: CloudProductInfoDto[];
  cloudProductsLoading: boolean;
  cloudProductsLoadError: null | Error;
  currentCloudProduct: null | CloudProductInfoDto;
  currentCloudProductsChanging: boolean;
  currentCloudProductChangeError: null | Error;
  computers: ComputerDto[];
  computersLoading: boolean;
  computersLoadError: null | Error;
  computerUpdating: boolean;
  computerUpdateError: null | Error;
}

const initialState: () => CloudProductsState = () => ({
  cloudProducts: [],
  cloudProductsLoading: false,
  cloudProductsLoadError: null,
  currentCloudProduct: null,
  currentCloudProductsChanging: false,
  currentCloudProductChangeError: null,
  computers: [],
  computersLoading: false,
  computersLoadError: null,
  computerUpdating: false,
  computerUpdateError: null,
});

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    CLOUD_PRODUCTS_LOADING(state) {
      state.cloudProductsLoadError = null;
      state.cloudProductsLoading = true;
    },

    CLOUD_PRODUCTS_LOADED(state, payload: CloudProductInfoDto[]) {
      state.cloudProducts = payload;
      state.cloudProductsLoading = false;
      state.currentCloudProduct = state.cloudProducts.find(
        cloudProduct => cloudProduct.cloudProductId === state.currentCloudProduct?.cloudProductId,
      );
    },

    CLOUD_PRODUCTS_LOAD_ERROR(state, payload) {
      state.cloudProductsLoadError = getErrorMessage(payload);
      state.cloudProductsLoading = false;
    },

    CLOUD_PRODUCT_CHANGING(state) {
      state.currentCloudProductChanging = true;
      state.currentDongleChangeError = null;
    },

    CLOUD_PRODUCT_CHANGED(state) {
      state.currentDongleChanging = false;
    },

    CLOUD_PRODUCT_CHANGE_ERROR(state, payload) {
      state.currentDongleChangeError = getErrorMessage(payload);
      state.currentDongleChanging = false;
    },

    SELECT_CLOUD_PRODUCT(state, cloudProduct) {
      state.currentCloudProduct = cloudProduct;
    },

    COMPUTERS_LOADING(state) {
      state.computers = [];
      state.computersLoadError = null;
      state.computersLoading = true;
    },

    COMPUTERS_LOADED(state, computers) {
      state.computers = computers;
      state.computersLoading = false;
    },

    COMPUTERS_LOAD_ERROR(state, payload) {
      state.computersLoading = false;
      state.computersLoadError = getErrorMessage(payload);
    },

    COMPUTER_UPDATING(state) {
      state.computerUpdating = true;
      state.computerUpdateError = null;
    },

    COMPUTER_UPDATED(state) {
      state.computerUpdating = false;
    },

    COMPUTER_UPDATE_ERROR(state, payload) {
      state.computerUpdateError = getErrorMessage(payload);
      state.computerUpdating = false;
    },

    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
  },
  actions: {
    async loadCloudProducts({ commit, rootGetters }) {
      try {
        commit('CLOUD_PRODUCTS_LOADING');
        const response = await getCloudApi(rootGetters['login/accessToken']).cloudControllerFindAllByOrganization(
          rootGetters['organizations/organizationId'],
        );
        commit('CLOUD_PRODUCTS_LOADED', response.data);
      } catch (error) {
        commit('CLOUD_PRODUCTS_LOAD_ERROR', error);
        logger.captureStoreError('loadCloudProducts', error);
      }
    },

    async loadComputers({ commit, rootGetters }, cloudProductId) {
      commit('COMPUTERS_LOADING');
      try {
        const response = await getCloudApi(rootGetters['login/accessToken']).cloudControllerFindComputersByCloudProduct(
          cloudProductId,
        );
        commit('COMPUTERS_LOADED', response.data);
      } catch (error) {
        commit('COMPUTERS_LOAD_ERROR', error);
        logger.captureStoreError('loadComputers', error, { cloudProductId });
      }
    },

    async assignCloudProduct({ commit, dispatch, rootGetters }, { cloudProductId, userId }) {
      commit('CLOUD_PRODUCT_CHANGING');
      try {
        await getCloudApi(rootGetters['login/accessToken']).cloudControllerAssignCloudProduct({
          cloudProductId,
          userId,
        });
        commit('CLOUD_PRODUCT_CHANGED');
        await dispatch('loadCloudProducts');
      } catch (error) {
        commit('CLOUD_PRODUCT_CHANGE_ERROR', error);
        logger.captureStoreError('assignCloudProduct', error, { cloudProductId, userId });
      }
    },

    async unAssignCloudProduct({ commit, dispatch, rootGetters }, cloudProductId) {
      commit('CLOUD_PRODUCT_CHANGING');
      try {
        await getCloudApi(rootGetters['login/accessToken']).cloudControllerUnAssignCloudProduct({ cloudProductId });
        commit('CLOUD_PRODUCT_CHANGED');
        await dispatch('loadCloudProducts');
      } catch (error) {
        commit('CLOUD_PRODUCT_CHANGE_ERROR', error);
        logger.captureStoreError('unAssignCloudProduct', error, { cloudProductId });
      }
    },

    async updateComputer({ commit, dispatch, rootGetters }, { computerId, name, isDeleted }) {
      commit('COMPUTER_UPDATING');
      try {
        await getCloudApi(rootGetters['login/accessToken']).cloudControllerUpdateComputer({
          computerId,
          name,
          isDeleted,
        });
        commit('COMPUTER_UPDATED');
        await dispatch('loadComputers', rootGetters['cloudProducts/currentCloudProductId']);
      } catch (error) {
        commit('COMPUTER_UPDATE_ERROR', error);
        logger.captureStoreError('updateComputer', error, { computerId, name, isDeleted });
      }
    },

    resetState({ commit }) {
      commit('RESET_STATE');
    },
  },
  getters: {
    cloudProducts: state => state.cloudProducts,
    cloudProductsLoading: state => state.cloudProductsLoading,
    cloudProductsLoadError: state => state.cloudProductsLoadError,
    computers: state => state.computers,
    computersLoading: state => state.computersLoading,
    computersLoadError: state => state.computersLoadError,
    currentCloudProduct: state => state.currentCloudProduct,
    currentCloudProductLicenseCode: state => state.currentCloudProduct?.licenseCode,
    currentCloudProductId: state => state.currentCloudProduct?.cloudProductId,
  },
};
