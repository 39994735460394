import { add, format } from 'date-fns';

// DATE-FNS FORMAT !

// MMDDYYYYY
export const MDY_DOTS_DATE_FORMAT = 'MM.dd.yyyy';
export const MDY_DATE_FORMAT = 'MMMM d, yyyy';
export const MDY_SHORT_MONTH_DATE_FORMAT = 'MMM d, yyyy';
export const MDY_FULL_FORMAT = 'MMMM d, yyyy. h:mm a';
export const MDY_SLASHES_MONTH_DAY_YEAR_FORMAT = 'MM/dd/yyyy';
// DDMMYYYYY
export const STD_SLASHES_DATE_FORMAT = 'dd/MM/yyyy';
export const STD_SLASHES_SHORT_DATE_FORMAT = 'd/M/yyyy';
export const STD_CONCATENATED_DATE_FORMAT = 'ddMMyyyy';
export const STD_DOTS_DATE_FORMAT = 'dd.MM.yyyy';
export const STD_MONTH_YEAR_FORMAT = 'MMMM yyyy';
export const STD_WEEKDAY_DAY_MONTH_YEAR = 'LLLL yyyy';

// YYYYMMDD
export const ISO_DATE_FORMAT = 'yyyy-MM-dd';
export const ISO_FULL_DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const ISO_MS_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx";
export const ISO_SLASHES_DATE_FORMAT = 'yyyy/MM/dd';
export const FEDEX_T_DATE_FORMAT = "yyyy-MM-dd'T'hh:mm:ss";
export const FEDEX_DASH_DATE_FORMAT = 'yyyy.MM.dd-hh:mm:ss';

/// OTHER
export const EMPTY_EXPIRY_DATE = '00000000';
export const LAST_DAY_OF_MONTH_FOR_NEW_EXPIRY_DATE = 29;
export const TODAY_ISO_STR = format(new Date(), ISO_DATE_FORMAT);
export const ONE_YEAR_LATER_ISO_STR = format(add(new Date(), { years: 1 }), ISO_DATE_FORMAT);
export const TWO_YEARS_LATER_ISO_STR = format(add(new Date(), { years: 2 }), ISO_DATE_FORMAT);
export const YEAR_FORMAT = format(new Date(), 'yyyy');
