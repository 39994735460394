<template>
  <v-avatar
    :size="`${size}px`"
    class="avatar"
    :style="{ 'background-color': background, border: background === '#FFFFFF' ? '1px solid #d2d2d7' : '' }"
  >
    <img v-if="getImg()" alt="Avatar" :src="getImg()" @error="onImageError()" width="64" height="64" />
    <span v-else-if="fullName" class="avatar-initials" :style="initialsSpanStyle">{{ `${initials}` || '?' }}</span>
    <v-icon v-else large>mdi-account-circle</v-icon>
    <slot></slot>
  </v-avatar>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { concatInitials } from '@/shared/functions';

@Component
export default class MoaAvatar extends Vue {
  @Prop({ default: null }) img: undefined | string;
  @Prop({ default: null }) fullName: undefined | string;
  @Prop({ default: 36 }) size!: number;

  private backgroundColors = [
    '#BFDF95', //BLACK FONT
    '#BCD6FB', //BLACK FONT
    '#FECC7C', //BLACK FONT
    '#FDBDBD', //BLACK FONT
    '#DFCCFB', //BLACK FONT
    '#D4D4D4', //BLACK FONT
    '#FFFFFF', //BLACK FONT
    '#000000', //WHITE FONT
    '#584364', //WHITE FONT
    '#8E230B', //WHITE FONT
    '#4B4B4B', //WHITE FONT
    '#6C410E', //WHITE FONT
  ];

  private error: null | string = null;

  getImg() {
    return this.error ? undefined : this.img;
  }

  get background(): string {
    return !this.getImg() && this.fullName
      ? this.backgroundColors[(this.fullName?.length || 0) % this.backgroundColors.length]
      : '#FFFFFF';
  }

  get font(): string {
    return `${Math.floor(this.size / 2.5)}px/${this.size}px inherit`;
  }

  setAvatarTextColor(hex: string): string {
    return this.backgroundColors.indexOf(hex) <= 6 ? '#000000' : '#FFFFFF';
  }

  get initialsSpanStyle(): Record<string, string> {
    return {
      'font-size': `${Math.floor(this.size / 2.5)}px`,
      'line-height': `${this.size}px`,
      color: this.background && this.setAvatarTextColor(this.background),
    };
  }

  get initials() {
    return this.fullName && concatInitials(this.fullName);
  }

  onImageError() {
    this.error = `Warning: can not load image ${this.img}`;
    console.warn(this.error);
  }
}
</script>
