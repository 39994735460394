export const CAST_ORGANIZATION_ID = 999;

export enum USER_GROUP_IDS {
  USER = 1,
  ADMIN = 2,
}

export enum USER_ROLE_IDS {
  DEMO_USER_ROLE_ID = 1,
  OWNER_USER_ROLE_ID = 2,
  MEMBER_USER_ROLE_ID = 3,
  SUPER_MANAGER_ROLE_ID = 4,
  STAFF_MANAGER_ROLE_ID = 5,
  DONGLE_MANAGER_USER_ROLE_ID = 6,
  VIRTUAL_UTILITIES_USER_ROLE_ID = 7,
  NOBODY_USER_ROLE_ID = 99,
}

export const EMPTY_USER_ROLE_NAME = 'emptyRole';

export enum CUSTOMER_PERMISSIONS {
  CAN_BUY = 'canBuy',
  CAN_MANAGE_ORGANIZATION = 'canManageOrganization',
}

export enum ADMIN_PERMISSIONS {
  CAN_EDIT_DONGLES = 'canEditDongles',
  CAN_RENEW_UPGRADE_DONGLES = 'canRenewUpgradeDongles',
  CAN_MANAGE_USERS = 'canManageUsers',
}

export const PERMISSIONS = { ...CUSTOMER_PERMISSIONS, ...ADMIN_PERMISSIONS };
