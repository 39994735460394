import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';

import {
  adminCloudProducts,
  adminDemos,
  adminDiscounts,
  adminDongles,
  adminInventory,
  adminOrders,
  adminOrganizations,
  adminReleases,
  adminUploads,
  adminUsers,
  cloudProducts,
  customerDownloads,
  demo,
  dictionary,
  dongles,
  feedback,
  invoices,
  login,
  organizations,
  shop,
  snackbar,
  support,
  adminPendingUsers,
} from './modules';
import { initialState } from '@/store/initialState/initialState';
import { APP_MUTATIONS } from '@/store/mutations/mutations';
import { APP_ACTIONS } from '@/store/actions/actions';

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  state: initialState,
  mutations: APP_MUTATIONS,
  actions: APP_ACTIONS,
  getters: {},
  modules: {
    adminDemos,
    adminDiscounts,
    adminDongles,
    adminInventory,
    adminOrganizations,
    adminOrders,
    adminUploads,
    adminReleases,
    adminUsers,
    adminCloudProducts,
    login,
    shop,
    dictionary,
    invoices,
    organizations,
    customerDownloads,
    demo,
    dongles,
    snackbar,
    feedback,
    cloudProducts,
    support,
    adminPendingUsers,
  },
  plugins: [
    //createLogger(),
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key),
      },
    }),
  ],
});
