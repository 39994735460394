import { getDonglesApi, getErrorMessage, isKnownError } from '@/castapi/helpers';
import { DongleActivityDto, DongleLicenseDto, HttpExceptionDto, ProductInfoDto } from '@/castapi';
import { AppLogger } from '@/logger';

const logger = new AppLogger('dongles state');

interface DonglesState {
  dongles: ProductInfoDto[];
  donglesLoading: boolean;
  donglesLoadError: null | Error;
  currentDongle: null | ProductInfoDto;
  currentDongleChanging: boolean;
  currentDongleChangeError: null | Error;
  isRenewUpgrade: boolean;
  dongleInserting: boolean;
  dongleInsertError: null | Error;
  dongleActivitiesLoading: boolean;
  dongleActivitiesLoadError: null | string;
  dongleActivities: DongleActivityDto[];
  dongleLicensesLoading: boolean;
  dongleLicensesLoadError: null | string;
  dongleLicenses: DongleLicenseDto[];
  dongleReauthCodeGenerating: boolean;
  dongleReauthCodeError: null | Error;
  dongleReauthCode: null | string;
  dongleEmergencyCodeGenerating: boolean;
  dongleEmergencyCodeError: null | Error;
}

const initialState: () => DonglesState = () => ({
  dongles: [],
  donglesLoading: false,
  donglesLoadError: null,
  currentDongle: null,
  currentDongleChanging: false,
  currentDongleChangeError: null,
  isRenewUpgrade: true,
  dongleInserting: false,
  dongleInsertError: null,
  dongleActivitiesLoading: false,
  dongleActivitiesLoadError: null,
  dongleActivities: [],
  dongleReauthCodeGenerating: false,
  dongleReauthCodeError: null,
  dongleReauthCode: null,
  dongleEmergencyCodeGenerating: false,
  dongleEmergencyCodeError: null,
  dongleLicenses: [],
  dongleLicensesLoading: false,
  dongleLicensesLoadError: null,
});

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    DONGLES_LOADING(state) {
      state.donglesLoadError = null;
      state.donglesLoading = true;
    },

    DONGLES_LOADED(state, payload: ProductInfoDto[]) {
      state.dongles = payload;
      state.donglesLoading = false;
      state.currentDongle = state.dongles.find(dongle => dongle.dongleId === state.currentDongle?.dongleId);
    },

    DONGLES_LOAD_ERROR(state, payload) {
      state.donglesLoadError = getErrorMessage(payload);
      state.donglesLoading = false;
    },

    DONGLE_CHANGING(state) {
      state.currentDongleChanging = true;
      state.currentDongleChangeError = null;
    },

    DONGLE_CHANGED(state) {
      state.currentDongleChanging = false;
    },

    DONGLE_CHANGE_ERROR(state, payload) {
      state.currentDongleChangeError = getErrorMessage(payload);
      state.currentDongleChanging = false;
    },

    SELECT_DONGLE(state, dongle) {
      state.currentDongle = dongle;
    },

    DONGLE_INSERTING(state) {
      state.dongleInserting = true;
      state.dongleInsertError = null;
    },

    DONGLE_INSERTED(state) {
      state.dongleInserting = false;
    },

    DONGLE_INSERT_ERROR(state, payload) {
      state.dongleInsertError = getErrorMessage(payload);
      state.dongleInserting = false;
    },

    DONGLE_ACTIVITIES_LOADING(state) {
      state.dongleActivities = [];
      state.dongleActivitiesLoadError = null;
      state.dongleActivitiesLoading = true;
    },

    DONGLE_ACTIVITIES_LOADED(state, activities) {
      state.dongleActivities = activities;
      state.dongleActivitiesLoading = false;
    },

    DONGLE_ACTIVITIES_LOAD_ERROR(state, payload) {
      state.dongleActivitiesLoading = false;
      state.dongleActivitiesLoadError = getErrorMessage(payload);
    },

    DONGLE_LICENSES_LOADING(state) {
      state.dongleLicenses = [];
      state.dongleLicensesLoadError = null;
      state.dongleLicensesLoading = true;
    },

    DONGLE_LICENSES_LOADED(state, activities) {
      state.dongleLicenses = activities;
      state.dongleLicensesLoading = false;
    },

    DONGLE_LICENSES_LOAD_ERROR(state, payload) {
      state.dongleLicensesLoading = false;
      state.dongleLicensesLoadError = getErrorMessage(payload);
    },

    DONGLE_REAUTH_CODE_GENERATING(state) {
      state.dongleReauthCodeGenerating = true;
      state.dongleReauthCodeError = null;
      state.dongleReauthCode = null;
    },

    DONGLE_REAUTH_CODE_GENERATED(state, code) {
      state.dongleReauthCodeGenerating = false;
      state.dongleReauthCode = code;
    },

    DONGLE_REAUTH_CODE_ERROR(state, payload) {
      state.dongleReauthCodeGenerating = false;
      state.dongleReauthCodeError = getErrorMessage(payload);
    },

    DROP_DONGLE_REAUTH_CODE(state) {
      state.dongleReauthCodeGenerating = false;
      state.dongleReauthCodeError = null;
      state.dongleReauthCode = null;
    },

    DONGLE_EMERGENCY_CODE_GENERATING(state) {
      state.dongleEmergencyCodeGenerating = true;
      state.dongleEmergencyCodeError = null;
    },

    DONGLE_EMERGENCY_CODE_GENERATED(state, emergencyCode) {
      state.dongleEmergencyCodeGenerating = false;
      state.currentDongle = { ...state.currentDongle, emergencyCode };
    },

    DONGLE_EMERGENCY_CODE_ERROR(state, payload) {
      state.dongleEmergencyCodeGenerating = false;
      state.dongleEmergencyCodeError = getErrorMessage(payload);
    },

    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
  },
  actions: {
    async loadDongles({ commit, rootGetters }) {
      try {
        commit('DONGLES_LOADING');
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerFindAllByOrganization(
          rootGetters['organizations/organizationId'],
        );
        commit('DONGLES_LOADED', response.data);
      } catch (error) {
        commit('DONGLES_LOAD_ERROR', error);
        logger.captureStoreError('loadDongles', error);
      }
    },

    async insertDongle({ commit, dispatch, rootGetters }, data) {
      commit('DONGLE_INSERTING');
      try {
        await getDonglesApi(rootGetters['login/accessToken']).donglesControllerInsertDongleByOwner(data);
        commit('DONGLE_INSERTED');
        await dispatch('loadDongles');
      } catch (error) {
        commit('DONGLE_INSERT_ERROR', error);
        logger.captureStoreError('insertDongle', error, { data });
      }
    },

    async assignDongle({ commit, dispatch, rootGetters }, { dongleId, userId }: { dongleId: number; userId: number }) {
      commit('DONGLE_CHANGING');
      try {
        await getDonglesApi(rootGetters['login/accessToken']).donglesControllerAssignDongle({ dongleId, userId });
        commit('DONGLE_CHANGED');
        await dispatch('loadDongles');
      } catch (error) {
        commit('DONGLE_CHANGE_ERROR', error);
        logger.captureStoreError('assignDongle', error, { dongleId, userId });
      }
    },

    async unAssignDongle({ commit, dispatch, rootGetters }, dongleId) {
      commit('DONGLE_CHANGING');
      try {
        await getDonglesApi(rootGetters['login/accessToken']).donglesControllerUnAssignDongle({ dongleId });
        commit('DONGLE_CHANGED');
        await dispatch('loadDongles');
      } catch (error) {
        commit('DONGLE_CHANGE_ERROR', error);
        logger.captureStoreError('unAssignDongle', error, { dongleId });
      }
    },

    async loadDongleActivities({ commit, rootGetters }, dongleId) {
      commit('DONGLE_ACTIVITIES_LOADING');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerGetDongleActivities(
          dongleId,
        );
        commit('DONGLE_ACTIVITIES_LOADED', response.data);
      } catch (error) {
        commit('DONGLE_ACTIVITIES_LOAD_ERROR', error);
        logger.captureStoreError('loadDongleActivities', error, { dongleId });
      }
    },

    async loadDongleLicenses({ commit, rootGetters }, dongleId) {
      commit('DONGLE_LICENSES_LOADING');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerGetLearnDongleLicenses(
          dongleId,
        );
        commit('DONGLE_LICENSES_LOADED', response.data);
      } catch (error) {
        commit('DONGLE_LICENSES_LOAD_ERROR', error);
        logger.captureStoreError('loadDongleLicenses', error, { dongleId });
      }
    },

    async generateDongleReauthCode({ commit }, { dongleSerialCode }) {
      commit('DONGLE_REAUTH_CODE_GENERATING');
      try {
        const response = await getDonglesApi().donglesControllerGetDongleReauthCode(dongleSerialCode);
        commit('DONGLE_REAUTH_CODE_GENERATED', response.data);
      } catch (error) {
        commit('DONGLE_REAUTH_CODE_ERROR', error);
        if (isKnownError(error as HttpExceptionDto, 400, 'Dongle not assigned to any user')) {
          return;
        }
        logger.captureStoreError('generateDongleReauthCode', error, { dongleSerialCode });
      }
    },

    async generateDongleEmergencyCode({ commit, rootGetters }, dongleId) {
      commit('DONGLE_EMERGENCY_CODE_GENERATING');
      try {
        const response = await getDonglesApi(rootGetters['login/accessToken']).donglesControllerGetDongleEmergencyCode(
          dongleId,
        );
        commit('DONGLE_EMERGENCY_CODE_GENERATED', response.data);
      } catch (error) {
        commit('DONGLE_EMERGENCY_CODE_ERROR', error);
        logger.captureStoreError('generateDongleEmergencyCode', error, { dongleId });
      }
    },

    resetState({ commit }) {
      commit('RESET_STATE');
    },
  },
  getters: {
    dongles: state => state.dongles,
    donglesLoading: state => state.donglesLoading,
    donglesLoadError: state => state.donglesLoadError,
    currentDongle: state => state.currentDongle,
    currentDongleSerialCode: state => state.currentDongle?.serialCode,
    currentDongleId: state => state.currentDongle?.dongleId,
    dongleInserting: state => state.dongleInserting,
    dongleInsertError: state => state.dongleInsertError,
    dongleActivities: state => state.dongleActivities,
    dongleActivitiesLoading: state => state.dongleActivitiesLoading,
    dongleActivitiesLoadError: state => state.dongleActivitiesLoadError,
    dongleReauthCodeGenerating: state => state.dongleReauthCodeGenerating,
    dongleReauthCodeError: state => state.dongleReauthCodeError,
    dongleReauthCode: state => state.dongleReauthCode,
    dongleEmergencyCodeGenerating: state => state.dongleEmergencyCodeGenerating,
    dongleEmergencyCodeError: state => state.dongleEmergencyCodeError,
  },
};
