import {
  BucketApiFactory,
  Configuration,
  EditingUserDto,
  OrganizationsApiFactory,
  UserDto,
  UsersApiFactory,
  UsersItemDto,
  UserUpdateDto,
} from '@/castapi';
import { apiConfig } from '@/shared/constants';
import { uploadImage } from '@/store/modules/common/Files';
import { omit } from '@/shared/functions';
import { AppLogger } from '@/logger';
import { getErrorMessage, omitUserSecretFields } from '@/castapi/helpers';

const logger = new AppLogger('adminUsers state');

const initialState = (): {
  users: UsersItemDto[];
  editingUser: null | EditingUserDto;
  editingUserLoading: boolean;
  editingUserLoadError: null | string;
  organizationCreating: boolean;
  organizationCreationError: null | string;
  usersLoading: boolean;
  usersLoadError: null | string;
  userChanging: null | number;
  deleteUserError: null | string;
  updateUserError: null | string;
  passwordResetLinkCreating: null | number;
  passwordResetLinkError: null | string;
  passwordResetLink: string | null;
} => ({
  users: [],
  editingUser: null,
  editingUserLoading: false,
  editingUserLoadError: null,
  organizationCreating: false,
  organizationCreationError: null,
  usersLoading: false,
  usersLoadError: null,
  userChanging: null,
  deleteUserError: null,
  updateUserError: null,
  passwordResetLinkCreating: null,
  passwordResetLinkError: null,
  passwordResetLink: null,
});

const getUsersApi = (accessToken?) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return UsersApiFactory(config);
};

const getOrganizationsApi = (accessToken?) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return OrganizationsApiFactory(config);
};

const getBucketApi = (accessToken?) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return BucketApiFactory(config);
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    USERS_LOADING(state) {
      state.usersLoading = true;
      state.usersLoadError = null;
    },
    USERS_LOADED(state, users) {
      state.users = users;
      state.usersLoading = false;
    },
    USERS_LOAD_ERROR(state, payload) {
      state.usersLoading = false;
      state.usersLoadError = getErrorMessage(payload);
    },
    EDITING_USER_LOADING(state) {
      state.editingUserLoading = true;
      state.editingUser = null;
      state.editingUserLoadError = null;
    },
    EDITING_USER_LOADED(state, editingUser) {
      state.editingUser = editingUser;
      state.editingUserLoading = false;
    },
    EDITING_USER_LOAD_ERROR(state, payload) {
      state.editingUserLoadError = getErrorMessage(payload);
      state.editingUserLoading = false;
    },
    USER_CHANGING(state, userId) {
      state.userChanging = userId;
      state.changeUserError = null;
    },
    USER_DELETED(state) {
      state.userChanging = null;
    },
    USER_UPDATED(state) {
      state.userChanging = null;
    },
    DELETE_USER_ERROR(state, payload) {
      state.deleteUserError = getErrorMessage(payload);
      state.userChanging = null;
    },
    UPDATE_USER_ERROR(state, payload) {
      state.updateUserError = getErrorMessage(payload);
      state.userChanging = null;
    },
    PASSWORD_RESET_LINK_CREATING(state, userId) {
      state.passwordResetLinkCreating = userId;
      state.passwordResetLinkError = null;
      state.passwordResetLink = null;
    },
    PASSWORD_RESET_LINK_CREATED(state, link) {
      state.passwordResetLinkCreating = null;
      state.passwordResetLink = link;
    },
    PASSWORD_RESET_LINK_ERROR(state, payload) {
      state.passwordResetLinkCreating = null;
      state.passwordResetLinkError = getErrorMessage(payload);
    },
    ORGANIZATION_CREATING(state) {
      state.organizationCreateError = null;
      state.organizationCreating = true;
    },
    ORGANIZATION_CREATED(state) {
      state.organizationCreating = false;
    },
    ORGANIZATION_CREATION_ERROR(state, payload) {
      state.organizationCreating = false;
      state.organizationCreationError = getErrorMessage(payload);
    },
    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
  },
  actions: {
    async loadUsers({ commit, rootGetters }, payload) {
      const { limit, offset, searchText, sortBy, sortDesc } = payload || {};
      commit('USERS_LOADING');
      try {
        const response = await getUsersApi(rootGetters['login/accessToken']).usersControllerGetAllUsers(
          limit,
          offset,
          searchText,
          sortBy,
          sortDesc,
        );
        commit('USERS_LOADED', response.data);
      } catch (error) {
        commit('USERS_LOAD_ERROR', error);
        logger.captureStoreError('loadUsers', error, { limit, offset, searchText, sortBy, sortDesc });
      }
    },
    async loadEditingUser({ commit, rootGetters }, userId) {
      commit('EDITING_USER_LOADING');
      try {
        const response = await getUsersApi(rootGetters['login/accessToken']).usersControllerGetEditingUser(userId);
        commit('EDITING_USER_LOADED', response.data);
      } catch (error) {
        commit('EDITING_USER_LOAD_ERROR', error);
        logger.captureStoreError('loadEditingUser', error, { userId });
      }
    },
    async removeUser({ commit, rootGetters }, userId) {
      commit('USER_CHANGING', userId);
      try {
        await getUsersApi(rootGetters['login/accessToken']).usersControllerDeleteUser({ userId });
        commit('USER_DELETED');
      } catch (error) {
        commit('DELETE_USER_ERROR', error);
        logger.captureStoreError('removeUser', error, { userId });
      }
    },
    async removeUserFromOrganization({ commit, rootGetters }, { userId, organizationId }) {
      commit('USER_CHANGING', userId);
      try {
        await getUsersApi(rootGetters['login/accessToken']).usersControllerRemoveUserFromOrganization({
          userId,
          organizationId,
        });
        commit('USER_UPDATED');
      } catch (error) {
        commit('UPDATE_USER_ERROR', error);
        logger.captureStoreError('removeUserFromOrganization', error, { userId, organizationId });
      }
    },
    async updateUser({ commit, dispatch, rootGetters }, { user, organizationId, avatarImage }) {
      commit('USER_CHANGING', user.userId);
      try {
        let avatar: null | string = null;
        const accessToken = rootGetters['login/accessToken'];
        if (avatarImage) {
          avatar = await uploadImage(avatarImage, `user-avatars/${user.userId}`, getBucketApi(accessToken));
        }

        let updateBody = omit(
          user,
          Object.keys(user).filter(
            f => !['firstName', 'lastName', 'phoneNumber', 'email', 'avatar', 'viewedTours'].includes(f),
          ),
        ) as UserUpdateDto;
        if (avatarImage && avatar) {
          updateBody = { ...updateBody, avatar };
        }

        await getUsersApi(accessToken).usersControllerUpdate(user.userId, organizationId, updateBody);
        await dispatch('loadEditingUser', user.userId);
        commit('USER_UPDATED');
      } catch (error) {
        commit('UPDATE_USER_ERROR', error);
        const userToEdit = omitUserSecretFields(user);
        logger.captureStoreError('updateUser', error, { userToEdit, avatarImage });
      }
    },
    async createPasswordResetLink({ commit, rootGetters }, user: UserDto) {
      commit('PASSWORD_RESET_LINK_CREATING');
      try {
        const response = await getUsersApi(rootGetters['login/accessToken']).usersControllerResetUserPassword(
          user.email,
        );
        commit('PASSWORD_RESET_LINK_CREATED', response.data);
      } catch (error) {
        commit('PASSWORD_RESET_LINK_ERROR', error);
        const userToEdit = omitUserSecretFields(user);
        logger.captureStoreError('createPasswordResetLink', error, { userToEdit });
      }
    },
    async createOrganizationForUser({ commit, dispatch, rootGetters }, { userId, organizationName, countryRef }) {
      commit('ORGANIZATION_CREATING');
      try {
        await getOrganizationsApi(rootGetters['login/accessToken']).organizationsControllerCreateOrganization({
          userId,
          organizationName,
          countryRef,
        });
        commit('ORGANIZATION_CREATED');
        await dispatch('loadEditingUser', userId);
      } catch (error) {
        commit('ORGANIZATION_CREATION_ERROR', error);
        logger.captureStoreError('createOrganizationForUser', error, { userId, organizationName, countryRef });
      }
    },
    resetState({ commit }) {
      commit('RESET_STATE');
    },
  },
  getters: {
    users: state => state.users,
    usersLoading: state => state.usersLoading,
    usersLoadError: state => state.usersLoadError,
    userChanging: state => state.userChanging,
    deleteUserError: state => state.deleteUserError,
    updateUserError: state => state.updateUserError,
    passwordResetLinkCreating: state => state.passwordResetLinkCreating,
    passwordResetLinkError: state => state.passwordResetLinkError,
    passwordResetLink: state => state.passwordResetLink,
    editingUser: state => state.editingUser,
    editingUserId: state => state.editingUser?.userId,
    editingUserLoading: state => state.editingUserLoading,
    editingUserOrganizationId: state => state.editingUser?.organizationId,
    editingUserOrganizationName: state => state.editingUser?.editingUserOrganizationName,
    organizationCreationError: state => state.organizationCreationError,
  },
};
