import { apiConfig } from '@/shared/constants';
import { getErrorMessage, ISearchParams } from '@/castapi/helpers';
import { AppLogger } from '@/logger';
import {
  CloudApiFactory,
  CloudProductInfoDto,
  CloudProductSearchResponseDto,
  ComputerDto,
  Configuration,
  HttpExceptionDto,
} from '@/castapi';

const logger = new AppLogger('adminCloudProducts state');

interface AdminCloudProductsState {
  currentCloudProduct: null | CloudProductInfoDto;
  cloudProducts: CloudProductSearchResponseDto[];
  cloudProductsLoading: boolean;
  cloudProductsLoadError: null | string;
  cloudProductChanging: boolean;
  assignCloudProductError: null | string;
  organizationAndUserCloudProductsLoading: boolean;
  organizationAndUserCloudProductsLoadError: null | string;
  userCloudProductsLoading: boolean;
  userCloudProductsLoadError: null | string;
  organizationAndUserCloudProducts: CloudProductInfoDto[];
  computers: ComputerDto[];
  computersLoading: boolean;
  computersLoadError: null | string;
}

const initialState = (): AdminCloudProductsState => ({
  currentCloudProduct: null,
  cloudProducts: [],
  cloudProductsLoading: false,
  cloudProductsLoadError: null,
  cloudProductChanging: false,
  assignCloudProductError: null,
  organizationAndUserCloudProductsLoading: false,
  organizationAndUserCloudProductsLoadError: null,
  userCloudProductsLoading: false,
  userCloudProductsLoadError: null,
  organizationAndUserCloudProducts: [],
  computers: [],
  computersLoading: false,
  computersLoadError: null,
});

const getCloudApi = (accessToken?: string) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return CloudApiFactory(config);
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    SELECT_CLOUD_PRODUCT(state: AdminCloudProductsState, cloudProduct: CloudProductInfoDto) {
      state.currentCloudProduct = cloudProduct;
    },

    CLOUD_PRODUCTS_LOADING(state: AdminCloudProductsState) {
      state.cloudProductsLoadError = null;
      state.cloudProductsLoading = true;
    },

    CLOUD_PRODUCTS_LOADED(state: AdminCloudProductsState, payload: CloudProductSearchResponseDto[]) {
      state.cloudProducts = payload;
      state.cloudProductsLoading = false;
    },

    CLOUD_PRODUCTS_LOAD_ERROR(state: AdminCloudProductsState, error: Error | HttpExceptionDto) {
      state.cloudProductsLoadError = getErrorMessage(error);
      state.cloudProductsLoading = false;
    },

    CLOUD_PRODUCT_CHANGING(state: AdminCloudProductsState) {
      state.assignCloudProductError = null;
      state.cloudProductChanging = true;
    },

    CLOUD_PRODUCT_CHANGED(state: AdminCloudProductsState) {
      state.cloudProductChanging = false;
    },

    ASSIGN_CLOUD_PRODUCT_ERROR(state: AdminCloudProductsState, error: Error | HttpExceptionDto) {
      state.cloudProductChanging = false;
      state.assignCloudProductError = getErrorMessage(error);
    },

    ORGANIZATION_CLOUD_PRODUCTS_LOADING(state: AdminCloudProductsState) {
      state.organizationAndUserCloudProductsLoadError = null;
      state.organizationAndUserCloudProductsLoading = true;
    },

    ORGANIZATION_CLOUD_PRODUCTS_LOADED(state: AdminCloudProductsState, payload: CloudProductInfoDto[]) {
      state.organizationAndUserCloudProducts = payload;
      state.organizationAndUserCloudProductsLoading = false;
    },

    ORGANIZATION_CLOUD_PRODUCTS_LOAD_ERROR(state: AdminCloudProductsState, error: Error | HttpExceptionDto) {
      state.organizationAndUserCloudProductsLoadError = getErrorMessage(error);
      state.organizationAndUserCloudProductsLoading = false;
    },

    USER_CLOUD_PRODUCTS_LOADING(state: AdminCloudProductsState) {
      state.userCloudProductsLoadError = null;
      state.userCloudProductsLoading = true;
    },

    USER_CLOUD_PRODUCTS_LOADED(state: AdminCloudProductsState, payload: CloudProductInfoDto[]) {
      state.organizationAndUserCloudProducts = payload;
      state.userCloudProductsLoading = false;
    },

    USER_CLOUD_PRODUCTS_LOAD_ERROR(state: AdminCloudProductsState, error: Error | HttpExceptionDto) {
      state.userCloudProductsLoadError = getErrorMessage(error);
      state.userCloudProductsLoading = false;
    },

    COMPUTERS_LOADING(state: AdminCloudProductsState) {
      state.computers = [];
      state.computersLoadError = null;
      state.computersLoading = true;
    },

    COMPUTERS_LOADED(state: AdminCloudProductsState, computers: ComputerDto[]) {
      state.computers = computers;
      state.computersLoading = false;
    },

    COMPUTERS_LOAD_ERROR(state: AdminCloudProductsState, error: Error | HttpExceptionDto) {
      state.computersLoading = false;
      state.computersLoadError = getErrorMessage(error);
    },

    RESET_STATE(state: AdminCloudProductsState) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
  },
  actions: {
    async loadCloudProducts({ commit, rootGetters }, searchParam: ISearchParams) {
      const { limit, offset, searchText, sortBy, sortDesc } = searchParam || {};
      commit('CLOUD_PRODUCTS_LOADING', {
        limit: searchParam.limit,
        offset: searchParam.offset,
        searchText: searchParam.searchText,
      });
      try {
        const response = await getCloudApi(rootGetters['login/accessToken']).cloudControllerGetAllCloudProducts(
          searchParam.limit,
          searchParam.offset,
          searchParam.searchText,
          searchParam.sortBy,
          searchParam.sortDesc,
        );
        commit('CLOUD_PRODUCTS_LOADED', response.data);
      } catch (error) {
        commit('CLOUD_PRODUCTS_LOAD_ERROR', error);
        logger.captureStoreError('loadCloudProducts', error, { limit, offset, searchText, sortBy, sortDesc });
      }
    },

    async loadVisibleCloudProducts({ dispatch, rootGetters }, fromPage: 'organizations' | 'user') {
      if (fromPage === 'organizations') {
        const organizationId = rootGetters['adminOrganizations/currentOrganizationId'];
        await dispatch('loadOrganizationCloudProducts', organizationId);
      } else if (fromPage === 'user') {
        const userId = rootGetters['adminUsers/editingUserId'];
        await dispatch('loadUserCloudProducts', userId);
      }
    },

    async loadOrganizationCloudProducts({ commit, rootGetters }, organizationId: number) {
      try {
        commit('ORGANIZATION_CLOUD_PRODUCTS_LOADING');
        const response = await getCloudApi(rootGetters['login/accessToken']).cloudControllerFindAllByOrganization(
          organizationId,
        );
        commit('ORGANIZATION_CLOUD_PRODUCTS_LOADED', response.data);
      } catch (error) {
        commit('ORGANIZATION_CLOUD_PRODUCTS_LOAD_ERROR', error);
        logger.captureStoreError('loadOrganizationCloudProducts', error, { organizationId });
      }
    },

    async loadUserCloudProducts({ commit, rootGetters }, userId: number) {
      try {
        commit('USER_CLOUD_PRODUCTS_LOADING');
        const response = await getCloudApi(rootGetters['login/accessToken']).cloudControllerFindAllByUser(userId);
        commit('USER_CLOUD_PRODUCTS_LOADED', response.data);
      } catch (error) {
        commit('USER_CLOUD_PRODUCTS_LOAD_ERROR', error);
        logger.captureStoreError('loadUserCloudProducts', error, { userId });
      }
    },

    async assignCloudProduct({ commit, dispatch, rootGetters }, { cloudProductId, userId, fromPage }) {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('CLOUD_PRODUCT_CHANGING');
        await getCloudApi(accessToken).cloudControllerAssignCloudProduct({ cloudProductId, userId });
        commit('CLOUD_PRODUCT_CHANGED');
        await dispatch('loadVisibleCloudProducts', fromPage);
      } catch (error) {
        commit('ASSIGN_CLOUD_PRODUCT_ERROR', error);
        logger.captureStoreError('assignCloudProduct', error, { cloudProductId, userId, fromPage });
      }
    },

    async unAssignCloudProduct({ commit, dispatch, rootGetters }, { cloudProductId, fromPage }) {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('CLOUD_PRODUCT_CHANGING');
        await getCloudApi(accessToken).cloudControllerUnAssignCloudProduct({ cloudProductId });
        commit('CLOUD_PRODUCT_CHANGED');
        await dispatch('loadVisibleCloudProducts', fromPage);
      } catch (error) {
        commit('ASSIGN_CLOUD_PRODUCT_ERROR', error);
        logger.captureStoreError('unAssignCloudProduct', error, { cloudProductId, fromPage });
      }
    },

    async selectCloudProduct({ commit, dispatch }, cloudProduct: CloudProductInfoDto) {
      commit('SELECT_CLOUD_PRODUCT', cloudProduct);
      await dispatch('loadComputers', cloudProduct.cloudProductId);
    },

    async loadComputers({ commit, rootGetters }, cloudProductId: number) {
      commit('COMPUTERS_LOADING');
      try {
        const response = await getCloudApi(rootGetters['login/accessToken']).cloudControllerFindComputersByCloudProduct(
          cloudProductId,
        );
        commit('COMPUTERS_LOADED', response.data);
      } catch (error) {
        commit('COMPUTERS_LOAD_ERROR', error);
        logger.captureStoreError('loadComputers', error, { cloudProductId });
      }
    },

    resetState({ commit }) {
      commit('RESET_STATE');
    },
  },
  getters: {
    cloudProducts: (state: AdminCloudProductsState) => state.cloudProducts,
    cloudProductsLoading: (state: AdminCloudProductsState) => state.cloudProductsLoading,
    cloudProductsLoadError: (state: AdminCloudProductsState) => state.cloudProductsLoadError,
    organizationCloudProducts: (state: AdminCloudProductsState) => state.organizationAndUserCloudProducts,
    organizationCloudProductsLoading: (state: AdminCloudProductsState) => state.organizationAndUserCloudProductsLoading,
    organizationCloudProductsLoadError: (state: AdminCloudProductsState) =>
      state.organizationAndUserCloudProductsLoadError,
    userCloudProducts: (state: AdminCloudProductsState) => state.organizationAndUserCloudProducts,
    userCloudProductsLoading: (state: AdminCloudProductsState) => state.userCloudProductsLoading,
    userCloudProductsLoadError: (state: AdminCloudProductsState) => state.userCloudProductsLoadError,
    computers: (state: AdminCloudProductsState) => state.computers,
    computersLoading: (state: AdminCloudProductsState) => state.computersLoading,
    computersLoadError: (state: AdminCloudProductsState) => state.computersLoadError,
  },
};
