import { AdminDemoDto, Configuration, DemoApiFactory, DemoUpdateDto } from '@/castapi';
import { apiConfig } from '@/shared/constants';
import { AppLogger } from '@/logger';
import Vue from 'vue';
import { pick } from '@/shared/functions';
import { getErrorMessage } from '@/castapi/helpers';

const logger = new AppLogger('adminDemos state');

type State = {
  demos: AdminDemoDto[];
  demosLoading: boolean;
  demosLoadError: null;
  demo: null | AdminDemoDto;
  demoLoading: boolean;
  demoLoadError: null;
  demoSaving: boolean;
  demoSaveError: null | Error;
};

const initialState = (): State => ({
  demos: [],
  demosLoading: false,
  demosLoadError: null,
  demo: null,
  demoLoading: false,
  demoLoadError: null,
  demoSaving: false,
  demoSaveError: null,
});

const getDemosApi = (accessToken?) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return DemoApiFactory(config);
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    DEMOS_LOADING(state) {
      state.demosLoadError = null;
      state.demosLoading = true;
    },
    DEMOS_LOADED(state, payload) {
      state.demos = payload;
      state.demosLoading = false;
    },
    DEMOS_LOAD_ERROR(state, error) {
      state.demosLoadError = getErrorMessage(error);
      state.demosLoading = false;
    },
    DEMO_LOADING(state) {
      state.demoLoadError = null;
      state.demoLoading = true;
    },
    DEMO_LOADED(state, payload) {
      state.demo = payload;
      state.demoLoading = false;
    },
    DEMO_LOAD_ERROR(state, error) {
      state.demosLoadError = getErrorMessage(error);
      state.demoLoading = false;
    },
    DEMO_UPDATE_IN_PROGRESS(state) {
      state.demoSaving = true;
      state.demoSaveError = null;
    },
    DEMO_UPDATE_SUCCESS(state, payload) {
      const ind = state.demos.findIndex(item => item.demoId === payload.demoId);
      Vue.set(state.demos, ind, payload);
      state.demoSaving = false;
    },
    DEMO_UPDATE_ERROR(state, error) {
      state.demoSaving = false;
      state.demoSaveError = getErrorMessage(error);
    },
    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
    RESET_EDITOR_STATE(state) {
      state.demo = null;
      state.demoLoading = false;
      state.demoLoadError = null;
      state.demoSaving = false;
      state.demoSaveError = null;
    },
  },
  actions: {
    async loadDemos({ commit, rootGetters }) {
      const accessToken = rootGetters['login/accessToken'];
      try {
        commit('DEMOS_LOADING');
        const response = await getDemosApi(accessToken).demoControllerAdminFindAll();
        commit('DEMOS_LOADED', response.data);
      } catch (error) {
        commit('DEMOS_LOAD_ERROR', error);
        logger.captureStoreError('loadDemos', error);
      }
    },
    async loadDemo({ commit, rootGetters }, id) {
      const accessToken = rootGetters['login/accessToken'];
      try {
        commit('DEMO_LOADING');
        const response = await getDemosApi(accessToken).demoControllerAdminGet(id);
        commit('DEMO_LOADED', response.data);
      } catch (error) {
        commit('DEMO_LOAD_ERROR', error);
        logger.captureStoreError('loadDemos', error, { id });
      }
    },
    async updateDemo({ commit, rootGetters }, demo: AdminDemoDto) {
      try {
        commit('DEMO_UPDATE_IN_PROGRESS');
        const demoUpdateDto = pick(demo, ['downloadPath', 'description']) as DemoUpdateDto;
        await getDemosApi(rootGetters['login/accessToken']).demoControllerUpdate(demo.demoId, demoUpdateDto);
        commit('DEMO_UPDATE_SUCCESS', demo);
      } catch (error) {
        commit('DEMO_UPDATE_ERROR', error);
        logger.captureStoreError('updateDemo', error, { demo });
      }
    },
    resetState({ commit }) {
      commit('RESET_STATE');
    },
    resetEditorState({ commit }) {
      commit('RESET_EDITOR_STATE');
    },
  },
  getters: {
    demos: state => state.demos,
    demosLoading: state => state.demosLoading,
    demosLoadError: state => state.demosLoadError,
    demo: state => state.demo,
    demoLoading: state => state.demoLoading,
    demoLoadError: state => state.demoLoadError,
    demoSaving: state => state.demoSaving,
    demoSaveError: state => state.demoSaveError,
  },
};
