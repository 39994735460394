import { Configuration, DiscountDto, DiscountsApiFactory } from '@/castapi';
import { apiConfig } from '@/shared/constants';
import { AppLogger } from '@/logger';
import { getErrorMessage } from '@/castapi/helpers';

const logger = new AppLogger('adminDiscounts state');

const initialState = (): {
  discounts: DiscountDto[];
  discountsLoading: boolean;
  discountsLoadError: null | Error;
  discountProductTypes: [];
  discountProductTypesLoading: boolean;
  discountProductTypesLoadError: null | Error;
  discountSaving: boolean;
  discountSaveError: null | Error;
} => ({
  discounts: [],
  discountsLoading: false,
  discountsLoadError: null,
  discountProductTypes: [],
  discountProductTypesLoading: false,
  discountProductTypesLoadError: null,
  discountSaving: false,
  discountSaveError: null,
});

const getDiscountApi = (accessToken?) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return DiscountsApiFactory(config);
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    DISCOUNTS_LOADING(state) {
      state.discountsLoadError = null;
      state.discountsLoading = true;
    },
    DISCOUNTS_LOADED(state, payload) {
      state.discounts = payload;
      state.discountsLoading = false;
    },
    DISCOUNTS_LOAD_ERROR(state, error) {
      state.discountsLoadError = getErrorMessage(error);
      state.discountsLoading = false;
    },
    DISCOUNT_TYPES_LOADING(state) {
      state.discountProductTypesLoadError = null;
      state.discountProductTypesLoading = true;
    },
    DISCOUNT_TYPES_LOADED(state, payload) {
      state.discountProductTypes = payload;
      state.discountProductTypesLoading = false;
    },
    DISCOUNT_TYPES_LOAD_ERROR(state, error) {
      state.discountProductTypesLoadError = getErrorMessage(error);
      state.discountProductTypesLoading = false;
    },
    DISCOUNT_SAVING(state) {
      state.discountSaveError = null;
      state.discountSaving = true;
    },
    DISCOUNT_SAVED(state) {
      state.discountSaveError = null;
      state.discountSaving = false;
    },
    DISCOUNT_SAVE_ERROR(state, error) {
      state.discountSaveError = getErrorMessage(error);
      state.discountSaving = false;
    },
    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
  },
  actions: {
    async loadDiscounts({ commit, rootGetters }) {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('DISCOUNTS_LOADING');
        const response = await getDiscountApi(accessToken).discountsControllerFindAll();
        commit('DISCOUNTS_LOADED', response.data);
      } catch (error) {
        commit('DISCOUNTS_LOAD_ERROR', error);
        logger.captureStoreError('loadDiscounts', error);
      }
    },
    async loadAvailableDiscounts({ commit, rootGetters }, { orderId, userId, dealerPurchaseRequest }) {
      try {
        const accessToken = rootGetters['login/accessToken'];
        commit('DISCOUNTS_LOADING');
        const response = await getDiscountApi(accessToken).discountsControllerFindAvailable(
          userId,
          orderId,
          dealerPurchaseRequest,
        );
        commit('DISCOUNTS_LOADED', response.data);
      } catch (error) {
        commit('DISCOUNTS_LOAD_ERROR', error);
        logger.captureStoreError('loadAvailableDiscounts', error);
      }
    },
    async loadDiscountTypes({ commit, rootGetters }) {
      try {
        commit('DISCOUNT_TYPES_LOADING');
        const res = await getDiscountApi(rootGetters['login/accessToken']).discountsControllerGetDiscountProductTypes();
        commit('DISCOUNT_TYPES_LOADED', res.data);
      } catch (error) {
        commit('DISCOUNT_TYPES_LOAD_ERROR', error);
        logger.captureStoreError('loadDiscountTypes', error);
      }
    },
    async addDiscount({ commit, rootGetters }, data) {
      const accessToken = rootGetters['login/accessToken'];
      commit('DISCOUNT_SAVING');
      try {
        await getDiscountApi(accessToken).discountsControllerCreate(data);
        commit('DISCOUNT_SAVED');
      } catch (error) {
        commit('DISCOUNT_SAVE_ERROR', error);
        logger.captureStoreError('addDiscount', error, data);
      }
    },
    async updateDiscount({ commit, rootGetters }, data) {
      const accessToken = rootGetters['login/accessToken'];
      commit('DISCOUNT_SAVING');
      try {
        await getDiscountApi(accessToken).discountsControllerUpdate(data.discountId, data);
        commit('DISCOUNT_SAVED');
      } catch (error) {
        commit('DISCOUNT_SAVE_ERROR', error);
        logger.captureStoreError('updateDiscount', error, data);
      }
    },
    async removeDiscount({ commit, rootGetters }, id) {
      const accessToken = rootGetters['login/accessToken'];
      commit('DISCOUNT_SAVING');
      try {
        await getDiscountApi(accessToken).discountsControllerRemove(id);
        commit('DISCOUNT_SAVED');
      } catch (error) {
        commit('DISCOUNT_SAVE_ERROR', error);
        logger.captureStoreError('removeDiscount', error, { id });
      }
    },
    resetState({ commit }) {
      commit('RESET_STATE');
    },
  },
  getters: {
    discounts: state => state.discounts,
    discountProductTypes: state => state.discountProductTypes,
    discountProductTypesMap: state =>
      state.discountProductTypes.reduce(
        (acc, curr) => ({ ...acc, [curr.discountProductTypeId]: curr.description }),
        {},
      ),
    discountsLoading: state => state.discountsLoading,
    discountsLoadError: state => state.discountsLoadError,
    discountProductTypesLoading: state => state.discountProductTypesLoading,
    discountProductTypesLoadError: state => state.discountProductTypesLoadError,
    discountSaving: state => state.discountSaving,
    discountSaveError: state => state.discountSaveError,
  },
};
