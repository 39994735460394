import { Configuration, HttpExceptionDto, UserDto, UserRoleDto, UsersApiFactory } from '@/castapi';
import router, { getHomePath } from '@/router';
import { apiConfig, EMPTY_USER_ROLE_NAME, USER_ROLE_IDS } from '@/shared/constants';
import { AppLogger } from '@/logger';
import { getErrorMessage, isKnownError, omitUserSecretFields } from '@/castapi/helpers';

const logger = new AppLogger('login state');

const getUsersApi = (accessToken?) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return UsersApiFactory(config);
};

const initialState = (): {
  otpMode: boolean;
  twofaSetupQrCodeDataUrl: string | null;
  loggedUser: UserDto | null;
  accessToken: string | null;
  refreshToken: string | null;
  loginError: string | null;
  loggingIn: boolean;
  tokenValid: boolean;
  userInvitationNotFound: boolean;
  invitedUser: UserDto | null;
  reloadUserDataInProgress: boolean;
  reloadUserDataError: null;
} => ({
  otpMode: false,
  twofaSetupQrCodeDataUrl: null,
  loggedUser: null,
  accessToken: null,
  refreshToken: null,
  loginError: null,
  loggingIn: false,
  tokenValid: false,
  userInvitationNotFound: false,
  invitedUser: null,
  reloadUserDataInProgress: false,
  reloadUserDataError: null,
});

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    PASSWORD_VERIFIED(state, data) {
      state.otpMode = true;
      state.twofaSetupQrCodeDataUrl = data.twofaSetupQrCodeDataUrl;
      state.loggingIn = false;
    },
    ACCESS_TOKEN_RECEIVED(state, { accessToken, refreshToken }) {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    LOGIN_SUCCESSFUL(state, user) {
      state.loggedUser = user;
      state.loginError = null;
      state.loggingIn = false;
    },
    LOGGING_IN(state) {
      state.loggingIn = true;
      state.loginError = null;
    },
    LOGIN_FAILED(state, payload) {
      state.loggingIn = false;
      state.loginError = getErrorMessage(payload);
    },
    RELOAD_USER_DATA(state) {
      state.reloadUserDataInProgress = true;
      state.reloadUserDataError = null;
    },
    RELOAD_USER_DATA_SUCCESS(state, user) {
      state.loggedUser = user;
      state.reloadUserDataInProgress = false;
    },
    RELOAD_USER_DATA_ERROR(state, error) {
      state.reloadUserDataInProgress = false;
      state.reloadUserDataError = getErrorMessage(error);
    },
    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
    UPDATE(state, user) {
      state.loggedUser = user;
    },
    TOKEN_VALID(state) {
      state.loggingIn = false;
      state.loginError = null;
      state.tokenValid = true;
    },
    TOKEN_NOT_VALID(state) {
      state.loggingIn = false;
      state.loginError = null;
      state.tokenValid = false;
    },
    USER_INVITATION_DATA_LOADED(state, data) {
      state.invitedUser = data;
      state.loggingIn = false;
    },
    PASSWORD_CHANGED(state) {
      state.loggingIn = false;
    },
  },
  actions: {
    async LOGIN({ commit, dispatch }, user) {
      try {
        commit('LOGGING_IN');
        const response = await getUsersApi().usersControllerLogin(user);
        if (response.data?.accessToken === null) {
          commit('PASSWORD_VERIFIED', response.data);
          return;
        }
        commit('ACCESS_TOKEN_RECEIVED', response.data);
        await dispatch('GET_CURRENT_USER_DATA');
      } catch (error) {
        commit('LOGIN_FAILED', error);
        if (isKnownError(error as HttpExceptionDto, 400, 'Invalid email or password')) {
          return;
        }
        logger.captureStoreError('LOGIN', error, { userEmail: user.email });
      }
    },
    async LOGOUT({ commit, dispatch, getters }) {
      const accessToken = getters.accessToken;
      const refreshToken = getters.refreshToken;
      if (accessToken || refreshToken) {
        await getUsersApi().usersControllerLogout({ accessToken, refreshToken });
      }
      commit('RESET_STATE');
      dispatch('resetState', null, { root: true });
      dispatch('adminOrganizations/resetState', null, { root: true });
      dispatch('adminDiscounts/resetState', null, { root: true });
      dispatch('adminDongles/resetState', null, { root: true });
      dispatch('adminInventory/resetState', null, { root: true });
      dispatch('adminOrders/resetState', null, { root: true });
      dispatch('adminUploads/resetState', null, { root: true });
      dispatch('adminReleases/resetState', null, { root: true });
      dispatch('adminUsers/resetState', null, { root: true });
      dispatch('adminDemos/resetState', null, { root: true });
      dispatch('shop/resetState', null, { root: true });
      dispatch('invoices/resetState', null, { root: true });
      dispatch('demo/resetState', null, { root: true });
      dispatch('dongles/resetState', null, { root: true });
      dispatch('organizations/resetState', null, { root: true });
      dispatch('dictionary/resetState', null, { root: true });
      dispatch('snackbar/resetState', null, { root: true });
    },
    async GET_CURRENT_USER_DATA({ commit, dispatch, getters }) {
      const accessToken = getters.accessToken;
      if (!accessToken) {
        return;
      }
      try {
        const [response] = await Promise.all([
          getUsersApi(accessToken).usersControllerGetUser(),
          dispatch('organizations/getUserOrganizations', accessToken, { root: true }),
          dispatch('dictionary/getUserRoles', { accessToken }, { root: true }),
          dispatch('demo/loadDemos', accessToken, { root: true }),
        ]);
        commit('LOGIN_SUCCESSFUL', response.data);
        if (!getters.isAdmin) {
          dispatch('shop/getUserUnfinishedOrder', null, { root: true });
        }
        await dispatch('redirectLoggedUser');
      } catch (error) {
        commit('LOGIN_FAILED', error);
        logger.captureStoreError('GET_CURRENT_USER_DATA', error);
      }
    },
    async RELOAD_CURRENT_USER_DATA({ commit, dispatch, getters, rootGetters }, payload) {
      try {
        const accessToken = payload || getters.accessToken;
        // Note: need for legacy - detect that user role types outdated and reload them
        const roles = rootGetters['dictionary/userRoleTypes'];
        if (!roles.find(r => r.userRoleId === USER_ROLE_IDS.VIRTUAL_UTILITIES_USER_ROLE_ID)) {
          dispatch('dictionary/getUserRoles', { accessToken, force: true }, { root: true });
        }
        commit('RELOAD_USER_DATA');
        const [response] = await Promise.all([
          getUsersApi(accessToken).usersControllerGetUser(),
          dispatch('organizations/getUserOrganizations', accessToken, { root: true }),
          dispatch('demo/loadDemos', accessToken, { root: true }),
        ]);
        commit('RELOAD_USER_DATA_SUCCESS', response.data);
      } catch (error) {
        commit('RELOAD_USER_DATA_ERROR', error);
      }
    },
    async redirectLoggedUser() {
      const fullPath =
        router.currentRoute.query.redirect && router.currentRoute.query.redirect !== 'null'
          ? (router.currentRoute.query.redirect as string)
          : getHomePath();
      await router.push({ path: fullPath });
    },
    async REGISTER_USER({ commit, dispatch }, user) {
      try {
        commit('LOGGING_IN');
        const response = await getUsersApi().usersControllerRegister(user);
        commit('ACCESS_TOKEN_RECEIVED', response.data);
        await dispatch('GET_CURRENT_USER_DATA');
        // await router.push({ name: 'loginsignin' });
      } catch (error) {
        commit('LOGIN_FAILED', error);
        if (isKnownError(error as HttpExceptionDto, 409, /Organization with email '.*' already exists/)) {
          return;
        }
        logger.captureStoreError('REGISTER_USER', error, omitUserSecretFields(user));
      }
    },
    updateUser({ commit }, user) {
      commit('UPDATE', user);
    },
    async resetPassword({ commit }, email) {
      try {
        commit('LOGGING_IN');
        await getUsersApi().usersControllerSendResetPasswordEmail(email);
        await router.push({ name: 'loginresetconfirmation' });
        commit('LOGIN_SUCCESSFUL');
      } catch (error) {
        commit('LOGIN_FAILED', error);
        logger.captureStoreError('resetPassword', error, { email });
      }
    },
    async checkPasswordResetToken({ commit }, token) {
      try {
        commit('LOGGING_IN');
        const response = await getUsersApi().usersControllerCheckPasswordResetToken(token);
        if (response.data) {
          commit('TOKEN_VALID');
        } else {
          commit('TOKEN_NOT_VALID');
          await router.push({ name: 'loginresetexpired' });
        }
      } catch (error) {
        commit('LOGIN_FAILED', error);
        logger.captureStoreError('checkPasswordResetToken', error);
      }
    },
    async setNewPassword({ commit, dispatch }, { token, password }) {
      commit('LOGGING_IN');
      try {
        const response = await getUsersApi().usersControllerSetNewPassword({ token, password });
        commit('ACCESS_TOKEN_RECEIVED', response.data);
        await dispatch('GET_CURRENT_USER_DATA');
      } catch (error) {
        commit('LOGIN_FAILED', error);
        logger.captureStoreError('setNewPassword', error);
      }
    },
    async getUserByInvitation({ commit }, token) {
      commit('LOGGING_IN');
      try {
        const response = await getUsersApi().usersControllerGetUserByInvitation({ token });
        const user = response.data;
        if (user) {
          commit('USER_INVITATION_DATA_LOADED', user);
        } else {
          await router.push({ name: '404' });
        }
      } catch (error) {
        commit('LOGIN_FAILED', error);
        logger.captureStoreError('getUserByInvitation', error);
      }
    },
    async useUserInvitation({ commit, dispatch }, { token, user }) {
      commit('LOGGING_IN');
      try {
        const response = await getUsersApi().usersControllerUseUserInvitation({ token, user });
        commit('ACCESS_TOKEN_RECEIVED', response.data);
        await dispatch('GET_CURRENT_USER_DATA');
      } catch (error) {
        commit('LOGIN_FAILED', error);
        logger.captureStoreError('getUserByInvitation', error, { userEmail: user.email });
      }
    },
    async refreshToken({ commit, getters }) {
      try {
        const refreshToken = getters.refreshToken;
        if (!refreshToken) {
          return;
        }
        const response = await getUsersApi().usersControllerRefreshToken({ refreshToken });
        commit('ACCESS_TOKEN_RECEIVED', response.data);
      } catch (error) {
        logger.captureStoreError('refreshToken', error);
      }
    },
    async changePassword({ commit, getters }, { password, oldPassword }) {
      const accessToken = getters.accessToken;
      if (!accessToken) {
        return;
      }
      commit('LOGGING_IN');
      try {
        await getUsersApi(accessToken).usersControllerChangeUserPassword({
          newPassword: password,
          oldPassword,
        });
        commit('PASSWORD_CHANGED');
      } catch (error) {
        commit('LOGIN_FAILED', error);
        logger.captureStoreError('changePassword', error);
      }
    },
  },
  getters: {
    otpMode: state => state.otpMode,
    twofaSetupQrCodeDataUrl: state => state.twofaSetupQrCodeDataUrl,
    loggedIn: (state, getters) => Boolean(getters.userId),
    isAdmin: (state, getters, rootState, rootGetters) =>
      getters.userId && rootGetters['organizations/isAdmin'](getters.userId),
    roleId: (state, getters, rootState, rootGetters) =>
      getters.userId ? rootGetters['organizations/roleId'](getters.userId) || USER_ROLE_IDS.NOBODY_USER_ROLE_ID : null,
    roleName: (state, getters, rootState, rootGetters) =>
      getters.userId ? rootGetters['organizations/roleName'](getters.userId) || EMPTY_USER_ROLE_NAME : null,
    isOwner: (state, getters, rootState, rootGetters) => rootGetters['organizations/isOwner'](state.loggedUser?.userId),
    isDemo: (state, getters, rootState, rootGetters) => rootGetters['organizations/isDemo'](state.loggedUser?.userId),
    isMember: (state, getters, rootState, rootGetters) =>
      rootGetters['organizations/isMember'](state.loggedUser?.userId),
    isEmptyRole: (state, getters, rootState, rootGetters) =>
      rootGetters['organizations/isEmptyRole'](state.loggedUser?.userId),
    activeUserMainRoleWithPermissions: (state, getters, rootState, rootGetters): UserRoleDto =>
      getters.userId ? rootGetters['organizations/activeUserMainRoleWithPermissions'](getters.userId) : null,
    user: state => state.loggedUser,
    userId: state => state.loggedUser?.userId,
    accessToken: state => state.accessToken,
    refreshToken: state => state.refreshToken,
    loginError: state => state.loginError,
    loggingIn: state => state.loggingIn,
    invitedUser: state => state.invitedUser,
    userRoles: (state, getters, rootState, rootGetters) => rootGetters['organizations/activeUserRoles'](getters.userId),
  },
};
