import { Configuration, UploadDto, UploadsApiFactory } from '@/castapi';
import { apiConfig } from '@/shared/constants';
import Vue from 'vue';
import { AppLogger } from '@/logger';
import { getErrorMessage } from '@/castapi/helpers';

const logger = new AppLogger('adminUploads state');

type State = {
  uploads: UploadDto[];
  uploadsLoading: boolean;
  uploadsLoadError: null;
  uploadSaving: boolean;
  uploadSaveError: null | Error;
};

const initialState = (): State => ({
  uploads: [],
  uploadsLoading: false,
  uploadsLoadError: null,
  uploadSaving: false,
  uploadSaveError: null,
});

const getUploadsApi = (accessToken?) => {
  const config = new Configuration({
    basePath: apiConfig.basePath,
  });
  if (accessToken) {
    config.accessToken = accessToken;
  }
  return UploadsApiFactory(config);
};

export default {
  namespaced: true,
  state: initialState,
  mutations: {
    UPLOADS_LOADING(state) {
      state.uploadsLoadError = null;
      state.uploadsLoading = true;
    },
    UPLOADS_LOADED(state, payload) {
      state.uploads = payload;
      state.uploadsLoading = false;
    },
    UPLOADS_LOAD_ERROR(state, error) {
      state.uploadsLoadError = getErrorMessage(error);
      state.uploadsLoading = false;
    },
    UPLOAD_ADD_IN_PROGRESS(state) {
      state.uploadSaving = true;
      state.uploadSaveError = null;
    },
    UPLOAD_ADD_SUCCESS(state, payload) {
      state.uploads.push(payload);
      state.uploadSaving = false;
    },
    UPLOAD_ADD_ERROR(state, error) {
      state.uploadSaving = false;
      state.uploadSaveError = getErrorMessage(error);
    },
    UPLOAD_UPDATE_IN_PROGRESS(state) {
      state.uploadSaving = true;
      state.uploadSaveError = null;
    },
    UPLOAD_UPDATE_SUCCESS(state, payload) {
      const ind = state.uploads.findIndex(item => item.id === payload.id);
      Vue.set(state.uploads, ind, payload);
      state.uploadSaving = false;
    },
    UPLOAD_UPDATE_ERROR(state, error) {
      state.uploadSaving = false;
      state.uploadSaveError = getErrorMessage(error);
    },
    UPLOAD_DELETE_IN_PROGRESS(state) {
      state.uploadSaving = true;
      state.uploadSaveError = null;
    },
    UPLOAD_DELETE_SUCCESS(state, id) {
      const index = state.uploads.findIndex(item => item.id === id);
      state.uploads.splice(index, 1);
      state.uploadSaving = false;
    },
    UPLOAD_DELETE_ERROR(state, error) {
      state.uploadSaving = false;
      state.uploadSaveError = getErrorMessage(error);
    },
    RESET_STATE(state) {
      const state2 = initialState();
      Object.keys(state2).forEach((key: string) => {
        state[key] = state2[key];
      });
    },
    RESET_EDITOR_STATE(state) {
      state.uploadSaveError = null;
      state.uploadSaving = false;
    },
  },
  actions: {
    async loadUploads({ commit, rootGetters }) {
      const accessToken = rootGetters['login/accessToken'];
      try {
        commit('UPLOADS_LOADING');
        const response = await getUploadsApi(accessToken).uploadsControllerFindAll();
        commit('UPLOADS_LOADED', response.data);
      } catch (error) {
        commit('UPLOADS_LOAD_ERROR', error);
        logger.captureStoreError('loadUploads', error);
      }
    },
    async addUpload({ commit, rootGetters }, data) {
      const accessToken = rootGetters['login/accessToken'];
      commit('UPLOAD_ADD_IN_PROGRESS');
      try {
        const response = await getUploadsApi(accessToken).uploadsControllerCreate(data);
        commit('UPLOAD_ADD_SUCCESS', response.data);
      } catch (error) {
        commit('UPLOAD_ADD_ERROR', error);
        logger.captureStoreError('addUpload', error, { data });
      }
    },
    async updateUpload({ commit, rootGetters }, data) {
      const accessToken = rootGetters['login/accessToken'];
      commit('UPLOAD_UPDATE_IN_PROGRESS');
      try {
        await getUploadsApi(accessToken).uploadsControllerUpdate(data.id, data);
        commit('UPLOAD_UPDATE_SUCCESS', data);
      } catch (error) {
        commit('UPLOAD_UPDATE_ERROR', error);
        logger.captureStoreError('updateUpload', error, { data });
      }
    },
    async removeUpload({ commit, rootGetters }, id) {
      const accessToken = rootGetters['login/accessToken'];
      commit('UPLOAD_DELETE_IN_PROGRESS');
      try {
        await getUploadsApi(accessToken).uploadsControllerRemove(id);
        commit('UPLOAD_DELETE_SUCCESS', id);
      } catch (error) {
        commit('UPLOAD_DELETE_ERROR', error);
        logger.captureStoreError('removeUpload', error, { id });
      }
    },
    resetState({ commit }) {
      commit('RESET_STATE');
    },
    resetEditorState({ commit }) {
      commit('RESET_EDITOR_STATE');
    },
  },
  getters: {
    uploads: state => state.uploads,
    uploadsLoading: state => state.uploadsLoading,
    uploadsLoadError: state => state.uploadsLoadError,
    uploadSaving: state => state.uploadSaving,
    uploadSaveError: state => state.uploadSaveError,
  },
};
