import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/store';
import { ADMIN_PAGE_TITLE, DEFAULT_PAGE_TITLE, STORE_PAGE_TITLE } from '@/shared/constants';
import snackbarPlugin from '@/plugins/snackbar';
import { isCloudOn, isTestEnv } from '@/shared/functions';

Vue.use(VueRouter);

export type MoaRouterConfig = RouteConfig & { meta: { roles: string[] } };

export const routes: Array<MoaRouterConfig> = [
  {
    // Just for detect '/' path and then redirect to the home page depending on the user role
    path: '/',
    name: 'Home',
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/dongles',
    name: 'Dongles',
    component: () =>
      import(
        /* webpackChunkName: "Dongles" */
        '../components/moa-staff-admin/Dongles/Dongles.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  //Cloud Products
  {
    path: '/cloud-products',
    name: 'Cloud',
    component: () =>
      import(
        /* webpackChunkName: "Dongles" */
        '../components/moa-staff-admin/CloudProducts/CloudProducts.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
    hidden: !isCloudOn(),
  },
  // Users
  {
    path: '/pending-users',
    name: 'pendingUsers',
    component: () =>
      import(
        /* webpackChunkName: "Users" */
        '../components/moa-staff-admin/Users/PendingUsers.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () =>
      import(
        /* webpackChunkName: "Users" */
        '../components/moa-staff-admin/Users/Users.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/users/:id',
    name: 'edit-user',
    component: () =>
      import(
        /* webpackChunkName: "EditOrganizationAndUser" */
        '../components/moa-staff-admin/Users/EditUser.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Organizations
  {
    path: '/organizations',
    name: 'organizations',
    component: () =>
      import(
        /* webpackChunkName: "Organizations" */
        '../components/moa-staff-admin/Organizations/Organizations.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/organizations/new',
    name: 'create-account',
    component: () =>
      import(
        /* webpackChunkName: "CreateAccount" */
        '../components/moa-staff-admin/Organizations/CreateAccount.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/organizations/:id',
    name: 'edit-organization',
    component: () =>
      import(
        /* webpackChunkName: "EditOrganizationAndUser" */
        '../components/moa-staff-admin/Organizations/EditOrganization.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/organizations/:id/invoice-history',
    name: 'organization-invoice-history',
    component: () =>
      import(
        /* webpackChunkName: "OrganizationInvoiceHistory" */
        '../components/moa-staff-admin/Organizations/OrganizationInvoiceHistory.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Uploads
  {
    path: '/uploads',
    name: 'uploads',
    component: () =>
      import(
        /* webpackChunkName: "Uploads" */
        '../components/moa-staff-admin/Uploads/Uploads.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/uploads/new',
    name: 'add-upload-link',
    component: () =>
      import(
        /* webpackChunkName: "AddUploadLink" */
        '../components/moa-staff-admin/Uploads/EditUpload.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/uploads/:id',
    name: 'edit-upload-link',
    component: () =>
      import(
        /* webpackChunkName: "EditUploadLink" */
        '../components/moa-staff-admin/Uploads/EditUpload.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Demos
  {
    path: '/admin-demos',
    name: 'admin-demos',
    component: () =>
      import(
        /* webpackChunkName: "AdminDemos" */
        '../components/moa-staff-admin/Demos/DemosList.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/admin-demos/:id',
    name: 'admin-demos-edit',
    component: () =>
      import(
        /* webpackChunkName: "AdminDemosEdit" */
        '../components/moa-staff-admin/Demos/DemosEdit.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Discounts
  {
    path: '/discounts',
    name: 'discounts',
    component: () =>
      import(
        /* webpackChunkName: "Discounts" */
        '../components/moa-staff-admin/Discounts/Discounts.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/discounts/new',
    name: 'add-discount',
    component: () =>
      import(
        /* webpackChunkName: "EditDiscount" */
        '../components/moa-staff-admin/Discounts/EditDiscount.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/discounts/:id',
    name: 'edit-discount',
    component: () =>
      import(
        /* webpackChunkName: "EditDiscount" */
        '../components/moa-staff-admin/Discounts/EditDiscount.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Staff
  {
    path: '/staff',
    name: 'Staff',
    component: () =>
      import(
        /* webpackChunkName: "People" */
        '../components/moa-customer-portal/People/People.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/staff/:id',
    name: 'staff-user-profile',
    component: () =>
      import(
        /* webpackChunkName: "UserProfile" */
        '../components/moa-customer-portal/People/UserProfile.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Orders
  {
    path: '/orders',
    name: 'orders',
    component: () =>
      import(
        /* webpackChunkName: "Orders" */
        '../components/moa-staff-admin/Orders/Orders.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/orders/new',
    name: 'add-order',
    component: () =>
      import(
        /* webpackChunkName: "EditOrder" */
        '../components/moa-staff-admin/Orders/EditOrder.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/orders/:orderId',
    name: 'edit-order',
    component: () =>
      import(
        /* webpackChunkName: "EditOrder" */
        '../components/moa-staff-admin/Orders/EditOrder.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  {
    path: '/inventory',
    name: 'Inventory',
    component: () =>
      import(
        /* webpackChunkName: "Inventory" */
        '../components/moa-staff-admin/Inventory/Inventory.vue'
      ),
    meta: {
      roles: ['admin'],
      title: ADMIN_PAGE_TITLE,
    },
  },
  // Shop
  {
    path: '/shop',
    name: 'shop',
    component: () =>
      import(
        /* webpackChunkName: "Front" */
        '../components/shop/Front/Front.vue'
      ),
    meta: {
      roles: ['owner', 'demo', 'unauthorized'],
      title: STORE_PAGE_TITLE,
    },
  },
  {
    path: '/shop/cart',
    name: 'shop-cart',
    component: () =>
      import(
        /* webpackChunkName: "Cart" */
        '../components/shop/Cart/Cart.vue'
      ),
    meta: {
      roles: ['owner', 'demo'],
      title: STORE_PAGE_TITLE,
    },
  },
  {
    path: '/shop/checkout',
    name: 'shop-checkout',
    component: () =>
      import(
        /* webpackChunkName: "Checkout" */
        '../components/shop/Checkout/Checkout.vue'
      ),
    meta: {
      roles: ['owner', 'demo'],
      title: STORE_PAGE_TITLE,
    },
  },
  {
    path: '/shop/receipt/:orderId',
    name: 'shop-receipt',
    component: () =>
      import(
        /* webpackChunkName: "Receipt" */
        '../components/shop/Receipt/Receipt.vue'
      ),
    meta: {
      roles: ['owner', 'member', 'demo'],
      title: STORE_PAGE_TITLE,
    },
  },
  // Login
  {
    path: '/auth/forgot-password',
    name: 'loginforgotpassword',
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */
        '../components/login/ForgotPassword/ForgotPassword.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/registration/:token',
    name: 'loginregistrationwithtoken',
    component: () =>
      import(
        /* webpackChunkName: "Registration" */
        '../components/login/Registration/Registration.vue'
      ),
    meta: {
      roles: ['unauthorized'],
    },
  },
  {
    path: '/registration',
    name: 'loginregistration',
    component: () =>
      import(
        /* webpackChunkName: "Registration" */
        '../components/login/Registration/Registration.vue'
      ),
    meta: {
      roles: ['unauthorized'],
    },
  },
  {
    path: '/auth/reset-confirmation',
    name: 'loginresetconfirmation',
    component: () =>
      import(
        /* webpackChunkName: "ResetConfirmation" */
        '../components/login/ForgotPassword/ResetConfirmation.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/auth/reset-expired',
    name: 'loginresetexpired',
    component: () =>
      import(
        /* webpackChunkName: "ResetExpired" */
        '../components/login/ForgotPassword/ResetExpired.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/auth/set-new-password/:token',
    name: 'loginsetnewpassword',
    component: () =>
      import(
        /* webpackChunkName: "SetNewPassword" */
        '../components/login/ForgotPassword/SetNewPassword.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/auth/change-password',
    name: 'changepassword',
    component: () =>
      import(
        /* webpackChunkName: "ChangePassword" */
        '../components/login/ChangePassword/ChangePassword.vue'
      ),
    meta: {
      roles: ['demo', 'owner', 'member', 'admin'],
    },
  },
  {
    path: '/auth/change-password-confirmation',
    name: 'changepasswordconfirmation',
    component: () =>
      import(
        /* webpackChunkName: "ChangePassword" */
        '../components/login/ChangePassword/ChangePasswordConfirmation.vue'
      ),
    meta: {
      roles: ['demo', 'owner', 'member', 'admin'],
    },
  },
  {
    path: '/signin',
    name: 'loginsignin',
    component: () =>
      import(
        /* webpackChunkName: "Signin" */
        '../components/login/Signin/Signin.vue'
      ),
    meta: {
      roles: ['unauthorized'],
    },
  },
  {
    path: '/auth/signout',
    name: 'loginsignout',
    component: () =>
      import(
        /* webpackChunkName: "Signout" */
        '../components/login/Signout/Signout.vue'
      ),
    meta: {
      roles: ['owner', 'demo', 'member', 'admin', 'emptyRole'],
    },
  },
  {
    path: '/reauth',
    name: 'reauth',
    component: () =>
      import(
        /* webpackChunkName: "Reauth" */
        '../components/login/Reauthorisation/Reauthorisation.vue'
      ),
    meta: {
      roles: ['owner', 'demo', 'member'],
    },
  },
  // Downloads
  {
    path: '/downloads',
    name: 'downloads',
    component: () =>
      import(
        /* webpackChunkName: "Downloads" */
        '../components/moa-customer-portal/Downloads/Downloads.vue'
      ),
    meta: {
      roles: ['owner', 'demo', 'member'],
    },
  },
  {
    path: '/downloads/:id',
    name: 'product-downloads',
    component: () =>
      import(
        /* webpackChunkName: "ProductDownloads" */
        '../components/moa-customer-portal/Downloads/ProductDownloads.vue'
      ),
    meta: {
      roles: ['owner', 'demo', 'member'],
    },
  },
  {
    path: '/downloads/:id/info',
    name: 'product-info',
    component: () =>
      import(
        /* webpackChunkName: "DownloadInfo" */
        '../components/moa-customer-portal/Downloads/DownloadInfo.vue'
      ),
    meta: {
      roles: ['owner', 'demo', 'member'],
    },
  },
  // People
  {
    path: '/people',
    name: 'people',
    component: () =>
      import(
        /* webpackChunkName: "People" */
        '../components/moa-customer-portal/People/People.vue'
      ),
    meta: {
      roles: ['owner'],
    },
  },
  {
    path: '/people/:id',
    name: 'user-profile',
    component: () =>
      import(
        /* webpackChunkName: "UserProfile" */
        '../components/moa-customer-portal/People/UserProfile.vue'
      ),
    meta: {
      roles: ['demo', 'owner', 'member', 'admin'],
    },
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () =>
      import(
        /* webpackChunkName: "Invoices" */
        '../components/moa-customer-portal/Invoices/Invoices.vue'
      ),
    meta: {
      roles: ['owner'],
    },
  },
  // Products
  {
    path: '/products',
    name: 'products',
    component: () =>
      import(
        /* webpackChunkName: "Products" */
        '../components/moa-customer-portal/Products/Products.vue'
      ),
    meta: {
      roles: ['owner', 'member', 'demo'],
    },
  },
  // Demo
  {
    path: '/demo/:id',
    name: 'download-demo',
    component: () =>
      import(
        /* webpackChunkName: "DemoDownload" */
        '../components/moa-customer-portal/Demos/DemoDownload.vue'
      ),
    meta: {
      roles: ['owner', 'member', 'demo'],
    },
  },
  {
    path: '/demo/:id/result',
    name: 'download-demo-result',
    component: () =>
      import(
        /* webpackChunkName: "DemoDownloadResult" */
        '../components/moa-customer-portal/Demos/DemoDownloadResult.vue'
      ),
    meta: {
      roles: ['owner', 'member', 'demo'],
    },
  },
  {
    path: '/organization',
    name: 'organization',
    component: () =>
      import(
        /* webpackChunkName: "Organization" */
        '../components/moa-customer-portal/Organization/Organization.vue'
      ),
    meta: {
      roles: ['owner', 'emptyRole'],
    },
  },
  {
    path: '/profile',
    name: 'myprofile',
    component: () =>
      import(
        /* webpackChunkName: "MyProfile" */
        '../components/moa-customer-portal/MyProfile/MyProfile.vue'
      ),
    meta: {
      roles: ['owner', 'member', 'admin', 'demo'],
    },
  },
  {
    path: '/download-invoice/:token',
    name: 'invoicedownload',
    component: () =>
      import(
        /* webpackChunkName: "InvoiceDownload" */
        '../components/shop/Invoice/InvoiceDownload.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/access-denied',
    name: '403',
    component: () =>
      import(
        /* webpackChunkName: "403" */
        '../components/errors/403.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/page-not-found',
    name: '404',
    component: () =>
      import(
        /* webpackChunkName: "404" */
        '../components/errors/404.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/dongle-web-simulator',
    name: 'dongle-web-simulator',
    component: () =>
      import(
        /* webpackChunkName: "DongleWebSimulator" */
        '../components/debug/DongleWebSimulator.vue'
      ),
    meta: {
      roles: ['everyone'],
    },
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () =>
      import(
        /* webpackChunkName: "Signout" */
        '../components/shared/various/FeedbackForm.vue'
      ),
    meta: {
      roles: ['demo', 'owner', 'member', 'admin'],
    },
  },
  {
    path: '/feedbacks',
    name: 'all-feedback',
    component: () =>
      import(
        /* webpackChunkName: "Signout" */
        '../components/shared/various/AllFeedback.vue'
      ),
    meta: {
      roles: ['admin'],
    },
  },
  {
    path: '/feedbacks/:id',
    name: 'view-feedback',
    component: () =>
      import(
        /* webpackChunkName: "Signout" */
        '../components/shared/various/ViewFeedback.vue'
      ),
    meta: {
      roles: ['admin'],
    },
  },
  {
    path: '/support-request',
    name: 'supportRequest',
    component: () =>
      import(
        /* webpackChunkName: "Signout" */
        '../components/moa-customer-portal/TechSupportForm/TechSupportForm.vue'
      ),
    meta: {
      roles: ['owner', 'member'],
    },
  },
  {
    path: '/support-request-confirmation',
    name: 'supportRequestConfirmation',
    component: () =>
      import(
        /* webpackChunkName: "Signout" */
        '../components/moa-customer-portal/TechSupportForm/RequestConfirmation.vue'
      ),
    meta: {
      roles: ['owner', 'member'],
    },
  },
  {
    path: '*',
    redirect: { name: '404' },
    meta: {
      roles: ['everyone'],
    },
  },
].filter(route => !route.hidden);

const router = new VueRouter({ routes, mode: 'history' });

export const getHomePath = function () {
  if (store.getters['login/loggedIn']) {
    if (store.getters['login/isAdmin']) {
      return '/dongles';
    }
    if (store.getters['login/isMember']) {
      return '/products';
    } else if (store.getters['login/isEmptyRole']) {
      return '/organization';
    }
    return '/products';
  }
  return '/shop';
};

router.beforeEach((to, from, next) => {
  if (to.fullPath === '/') {
    next({ path: getHomePath() });
  }
  if (to.name === 'dongle-web-simulator') {
    if (isTestEnv()) {
      next();
    } else {
      next({ path: '/page-not-found' });
    }
  }
  // EVERYONE
  if (JSON.stringify(to.meta?.roles) === JSON.stringify(['everyone'])) {
    next();
    // UNAUTHORIZED ONLY
  } else if (JSON.stringify(to.meta?.roles) === JSON.stringify(['unauthorized'])) {
    if (store.getters['login/loggedIn']) {
      if (to.name === 'loginregistrationwithtoken') {
        snackbarPlugin.showMessageExt({
          message: 'Log out first to use the link.',
          topAndCentered: true,
          timeout: 60000,
        });
      }
      next({ path: getHomePath() });
    } else {
      if (!to.query.redirect && !['/', '/shop'].includes(from.fullPath)) {
        to.query.redirect = from.fullPath;
      }
      next();
    }
    // AUTHORIZED: OWNER, MEMBER, DEMO, ADMIN
  } else if (
    ['owner', 'member', 'demo', 'admin', 'emptyRole', 'unauthorized'].find(role => to.meta?.roles.includes(role))
  ) {
    if (store.getters['login/loggedIn']) {
      if (
        (store.getters['login/isAdmin'] && to.meta?.roles.includes('admin')) ||
        (store.getters['login/isOwner'] && to.meta?.roles.includes('owner')) ||
        (store.getters['login/isDemo'] && to.meta?.roles.includes('demo')) ||
        (store.getters['login/isMember'] && to.meta?.roles.includes('member')) ||
        (store.getters['login/isEmptyRole'] && to.meta?.roles.includes('emptyRole'))
      ) {
        next();
      } else {
        if (to.name === 'shop-cart' && store.getters['login/isMember']) {
          snackbarPlugin.showMessageExt({
            message:
              'You are logged in as a company member. You can only buy the product if you are the owner of the ' +
              'company. If you want to purchase a product, please log out and create an account as the owner of the ' +
              'company.',
            topAndCentered: true,
            timeout: 3600000,
          });
        }
        next({ path: getHomePath() });
      }
    } else {
      // UNAUTHORIZED ALLOWED
      if (to.meta?.roles.includes('unauthorized')) {
        next();
      } else {
        next({ path: '/signin', query: { redirect: to.fullPath } });
      }
    }
    // UNKNOWN ROLE
  } else {
    next({ path: '/access-denied' }); // make sure to always call next()!
    console.error('Error in role based access control');
  }
});

router.afterEach(to => {
  Vue.nextTick(() => {
    if (to.fullPath === getHomePath()) {
      if (store.getters['login/isAdmin']) {
        document.title = ADMIN_PAGE_TITLE;
      } else if (store.getters['login/isOwner'] || store.getters['login/isDemo']) {
        document.title = STORE_PAGE_TITLE;
      } else {
        document.title = DEFAULT_PAGE_TITLE;
      }
    } else {
      document.title = to.meta?.title || DEFAULT_PAGE_TITLE;
    }
  });
});

export default router;
